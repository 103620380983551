<template lang="html">
  <div class="ShareComponent">
    <transition name="fade">
      <div class="ShareComponent--Modal" v-if="showModal">
        <div class="ShareComponent--Header">
          <h1>{{$t('share_component_invite_friends')}}</h1>
          <i class="fa fa-times" v-on:click="close()"></i>
        </div>
        <hr />
        <div class="ShareComponent--Info">
          <p v-html="$t('share_component_invite_friends_text')"></p>
        </div>
        <hr>
        <div class="ShareComponent--Grid">
          <div class="ShareComponent--Button ShareComponentCopy" data-content="¡Copiado!" v-on:click="copyText()">
            <i class="fa fa-paperclip"></i>
            <p v-html="$t('share_component_copy')"></p>
          </div>
          <div class="ShareComponent--Button" :style="{color:network.color}" v-for="(network, index) in shareNetworks" v-on:click="shareTo(index)">
            <i :class="network.icon"></i>
            <p>{{network.name}}</p>
          </div>
        </div>
        <hr>
        <div class="ShareComponent--Advert">
          <p>{{$t('share_component_promotion')}}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ShareComponent',
  props:{
    id: {
      type: Number,
      default: ''
    }
  },
  data(){
    return{
      urlParams:{
        text:'¿Quieres ganar dinero por dar tu opinión? Únete a nuestra comunidad y ponte a prueba!',
        url:"https://app.wearetesters.com/#/registro?invitedBy=" + this.id,
        uniqueText:'¿Quieres ganar dinero por dar tu opinión? Únete a nuestra comunidad y ponte a prueba! https://app.wearetesters.com/#/registro?invitedBy=' + this.id
      },
      shareNetworks:[
        {name:'Correo electronico', icon:'fa fa-envelope', url:'mailto:?subject=[[text]]&body=[[uniqueText]]'},
        {name:'WhatsApp', color:'#25d366', icon:'fab fa-whatsapp', url:'https://api.whatsapp.com/send?text=[[uniqueText]]'},
        {name:'Facebook', color:'#3b5998', icon:'fab fa-facebook', url:'https://www.facebook.com/sharer/sharer.php?u=[[url]]&t=[[text]]'},
        {name:'Telegram', color:'#0088cc', icon:'fab fa-telegram', url:'https://t.me/share/url?url=[[url]]&text=[[text]]'},
        {name:'Twitter', color:'#1da1f2', icon:'fab fa-twitter', url:'https://twitter.com/intent/tweet?text=[[text]]&url=[[url]]'},
      ],
      showModal: false
    }
  },
  methods:{
    close(){
      this.showModal = false
      const vm = this
      setTimeout(
      function() {
        vm.$emit('close')
      }, 500);
    },
    shareTo(index){
      let url = this.shareNetworks[index].url
      for (let key in this.urlParams) url = url.replace("[["+key+"]]",this.urlParams[key]);
      window.open(url, '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    },
    copyText(){
      const el = document.createElement('textarea');
      el.value = this.urlParams.uniqueText;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      $(".ShareComponentCopy").addClass('ShareComponent--CopyText')
      setTimeout(() => {
        $(".ShareComponentCopy").removeClass('ShareComponent--CopyText')
      }, 2000)
    }
  },
  mounted(){
    this.showModal = true
  }
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900");
.ShareComponent {
  position: fixed;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  hr{
    width: 100%;
    height: 1px;
    opacity: 0.15;
    background: #828282;
    margin: 20px 0;
  }
  &--Modal{
    box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.6);
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: white;
    max-width: 400px;
    position: relative;
  }
  &--Header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    h1{
      font-family: 'Lato';
      color: white;
      background: linear-gradient( 90deg, #FF1564 0%, #FD7328 100%);
      box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.1);
      font-weight: bold;
      font-size: 26px;
      line-height: 42px;
      margin-left: -20px;
      padding: 0 25px 0 25px;
      position: absolute;
      left: -17px;
    }
    i{
      font-size: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
  &--Grid{
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: space-around;
  }
  &--CopyText{
    &:after{
      position:absolute;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #183153;
      color: white;
      content:attr(data-content);
      bottom: -35px;
      z-index: 1;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: white;
    }
    &:before{
      width: 2px;
      height: 2px;
      border: 5px solid transparent;
      border-bottom-color: #183153;
      pointer-events: none;
      transition: all 0.18s ease-out 0.18s;
      content: "";
      position: absolute;
      z-index: 1;
      bottom: -5px;
    }
  }
  &--Button{
    cursor: pointer;
    box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.1);
    background: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    &:hover{
      transition: background 1000ms linear;
      border: 1px solid #FF1564;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    i{
      font-size: 36px;
    }
    p{
      margin: 0px;
      font-family: Lato;
      font-weight: bold;
      font-size: 14px;
    }
  }
  &--Info{
    p{
      color: #341566;
      font-weight: bold;
      font-size: 15px;
    }
    b{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &--Advert{
    font-size: 13px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
    transform: translateY(0px);
  }
  .fade-enter-from,
  .fade-leave-to
  {
    transform: translateY(1000px);
  }
  @media only screen and ( max-width: 480px ) {
    &--Modal{
      width: 80%;
    }
    &--Header h1{
      font-size: 18px;
    }
    &--Info p{
      font-size: 13px;
    }
    &--Button{
      width: 80px;
      height: 80px;
      i{
        font-size: 28px;
      }
      p{
        font-size: 12px;
      }
    }
  }
  @media only screen and ( max-width: 360px ) {
    &--Grid{
      grid-template-columns: auto auto;
    }
  }
}
</style>
